import { createRouter, createWebHistory } from 'vue-router'
import FindTravelApp from '../components/client/FindTravelApp.vue'
import MyInvoices from '../components/user/bookings/MyInvoices.vue'
import NotFound from '../components/common/NotFound.vue'

const companyName = import.meta.env.VITE_COMPANY_NAME

const routes = [
    {
        path: '/:pathMatch(.*)',
        component: NotFound,
        name: 'not-found',
        meta: {
            title: 'Woops'
        }
    },

    {
        path: '/',
        component: () => import("@/components/user/auth/Login.vue"),
        name: 'user-login',
        meta: {
            title: 'Login - ' + companyName
        }
    },

    {
        path: '/forgot-password',
        component: () => import("@/components/user/auth/ForgotPassword.vue"),
        meta: {
            title: 'Forgot Password - ' + companyName
        }
    },

    {
        path: '/reset-password/:token',
        component: () => import("@/components/user/auth/ResetPassword.vue"),
        name: 'reset-password-form',
        meta: {
            title: 'Reset Password - ' + companyName
        }
    },

    {
        path: '/admin/login',
        component: () => import("@/components/admin/auth/AdminLogin.vue"),
        name: 'admin-login',
        meta: {
            title: 'Admin Login - ' + companyName
        }
    },

    {
        path: '/admin',
        component: () => import("@/components/admin/common-components/AdminDashboard.vue"),
        children: [

            {
                path: 'client-files',
                component: () => import("@/components/admin/client-files/ClientFilesList.vue"),
                name: 'client-files',
                meta: {
                    title: 'Client Files - ' + companyName
                }
            },

            {
                path: 'client-files/add',
                component: () => import("@/components/admin/client-files/ClientFilesAdminAdd.vue"),
                name: 'add-client-file',
                meta: {
                    title: 'Create Client File - ' + companyName
                }
            },

            {
                path: 'client-files/:id',
                name: 'edit-client-file',
                component: () => import("@/components/admin/client-files/ClientFilesAdminEdit.vue"),
                meta: {
                    title: 'Update Client File - ' + companyName
                }
            },

            {
                path: 'client-files/itinerary/:id',
                component: () => import("@/components/admin/itinerary-editing/ItineraryEditor.vue"),
                name: 'itinerary-edit'
            },

            {
                path: 'client-files/invoices/:id',
                name: 'invoices',
                component: () => import("@/components/admin/client-files/Invoices.vue")
            },

            {
                path: 'client-files/yield/:id',
                name: 'yield',
                component: () => import("@/components/admin/yield/Yield.vue")
            },

            {
                path: 'client-files/day-headers/:id',
                name: 'day-headers',
                component: () => import("@/components/admin/client-files/DayHeaders.vue")
            },

            {
                path: 'quick-quote',
                name: 'quick-quote',
                component: () => import("@/components/admin/quick-quote/QuickQuote.vue"),
                children: [
                    {
                        path: '',
                        name: 'list-quick-quote-services',
                        component: () => import("@/components/admin/quick-quote/QuickQuoteServiceList.vue"),
                        meta: {
                            title: 'Quick Quote Services - ' + companyName
                        }
                    },

                    {
                        path: ':id',
                        name: 'QuickQuoteService',
                        component: () => import("@/components/admin/quick-quote/QuickQuoteServiceEdit.vue"),
                        meta: {
                            title: 'Edit Quick Quote Service - ' + companyName
                        }
                    },

                    {
                        path: 'add',
                        name: 'add-service',
                        component: () => import("@/components/admin/quick-quote/QuickQuoteServiceAdd.vue"),
                        meta: {
                            title: 'Add Quick Quote Service - ' + companyName
                        }
                    },

                    {
                        path: 'test',
                        name: 'test-packages' +
                            '',
                        component: () => import("@/components/admin/quick-quote/TestPackages.vue"),
                        meta: {
                            title: 'Test Quick Quote Packages'
                        }
                    }
                ]
            },

            {
                path: 'agents',
                name: 'agents',
                component: () => import("@/components/admin/agents/Agents.vue"),
                children: [
                    {
                        path: '',
                        name: 'list-agents',
                        component: () => import("@/components/admin/agents/AgentList.vue"),
                        meta: {
                            title: 'Agents - ' + companyName
                        }
                    },


                    {
                        path: 'add',
                        name: 'add-agent',
                        component: () => import("@/components/admin/agents/AgentAdd.vue"),
                        meta: {
                            title: 'Create Agent - ' + companyName
                        }
                    },

                    {
                        path: ':id',
                        name: 'agent',
                        component: () => import("@/components/admin/agents/AgentEdit.vue"),
                        meta: {
                            title: 'Edit Agent - ' + companyName
                        }
                    },
                ]
            },

            {
                path: 'admins',
                name: 'admins',
                component: () => import("@/components/admin/admins/Admins.vue")  ,
                children: [
                    {
                        path: '',
                        name: 'list-admins',
                        component: () => import("@/components/admin/admins/AdminList.vue"),
                        meta: {
                            title: 'Admins - ' + companyName
                        }
                    },

                    {
                        path: 'add',
                        name: 'add-admin',
                        component: () => import("@/components/admin/admins/AdminAdd.vue"),
                        meta: {
                            title: 'Create Admin - ' + companyName
                        }
                    },

                    {
                        path: ':id',
                        name: 'edit-admin',
                        component: () => import("@/components/admin/admins/AdminEdit.vue"),
                        meta: {
                            title: 'Edit Admin - ' + companyName
                        }
                    }
                ]
            },

            {
                path: 'suppliers',
                name: 'suppliers',
                component: () => import("@/components/admin/suppliers/Suppliers.vue"),
                children: [
                    {
                        path: '',
                        name: 'list-suppliers',
                        component: () => import("@/components/admin/suppliers/SupplierList.vue"),
                        meta: {
                            title: 'Suppliers - ' + companyName
                        }
                    },

                    {
                        path: 'add',
                        name: 'add-supplier',
                        component: () => import("@/components/admin/suppliers/SupplierAdd.vue"),
                        meta: {
                            title: 'Create Supplier - ' + companyName
                        }

                    },

                    {
                        path: ':id',
                        name: 'edit-supplier',
                        component: () => import("@/components/admin/suppliers/SupplierEdit.vue"),
                        meta: {
                            title: 'Edit Supplier - ' + companyName
                        }

                    }
                ]

            },

            {
                path: 'attachments',
                name: 'attachments',
                component: () => import("@/components/admin/supplier-attachments/SupplierAttachments.vue"),
                children: [
                    {
                        path: '',
                        name: 'list-attachments',
                        component: () => import("@/components/admin/supplier-attachments/SupplierAttachmentsList.vue"),
                        meta: {
                            title: 'Attachments - ' + companyName
                        }
                    },

                    {
                        path: 'add',
                        name: 'add-attachment',
                        component: () => import("@/components/admin/supplier-attachments/SupplierAttachmentAdd.vue"),
                        meta: {
                            title: 'Attachments - ' + companyName
                        }
                    },

                    {
                        path: ':id',
                        name: 'edit-attachment',
                        component: () => import("@/components/admin/supplier-attachments/SupplierAttachmentEdit.vue"),
                        meta: {
                            title: 'Attachments - ' + companyName
                        }
                    }

                ]

            },

            {
                path: 'labels',
                name: 'labels',
                component: () => import("@/components/admin/labels/Labels.vue"),
                children: [
                    {
                        path: '',
                        name: 'list-labels',
                        component: () => import("@/components/admin/labels/LabelList.vue"),
                        meta: {
                            title: 'Labels - ' + companyName
                        }
                    }

                ]

            },

            {
                path: 'alerts',
                name: 'alerts',
                component: () => import("@/components/admin/itinerary-alerts/ItineraryAlerts.vue"),
                children: [
                    {
                        path: '',
                        name: 'list-alerts',
                        component: () => import("@/components/admin/itinerary-alerts/ItineraryAlertList.vue"),
                        meta: {
                            title: 'Alerts - ' + companyName
                        }
                    },

                    {
                        path: 'add',
                        name: 'add-alert',
                        component: () => import("@/components/admin/itinerary-alerts/ItineraryAlertAdd.vue"),
                        meta: {
                            title: 'Alerts - ' + companyName
                        }
                    },

                    {
                        path: ':id',
                        name: 'edit-alert',
                        component: () => import("@/components/admin/itinerary-alerts/ItineraryAlertEdit.vue"),
                        meta: {
                            title: 'Alerts - ' + companyName
                        }
                    }

                ]

            },

            {
                path: 'itinerary-locations',
                name: 'itinerary-locations',
                component: () => import("@/components/admin/itinerary-locations/ItineraryLocations.vue"),
                children: [
                    {
                        path: '',
                        name: 'list-locations',
                        component: () => import("@/components/admin/itinerary-locations/ItineraryLocationList.vue"),
                        meta: {
                            title: 'Locations - ' + companyName
                        }
                    },

                    {
                        path: 'add',
                        name: 'add-location',
                        component: () => import("@/components/admin/itinerary-locations/ItineraryLocationAdd.vue"),
                        meta: {
                            title: 'Add Location - ' + companyName
                        }
                    },

                    {
                        path: ':id',
                        name: 'edit-location',
                        component: () => import("@/components/admin/itinerary-locations/ItineraryLocationEdit.vue"),
                        meta: {
                            title: 'Edit Location - ' + companyName
                        }
                    }

                ]

            },

            {
                path: 'guide',
                name: 'admin-guide',
                component: () => import("@/components/admin/guide/AdminGuide.vue"),
                children: [
                    {
                        path: '',
                        name: 'admin-guide-index',
                        component: () => import("@/components/admin/guide/AdminGuideIndex.vue")
                    },
                    {
                        path: ':id',
                        name: 'admin-guide-article',
                        component: () => import("@/components/admin/guide/AdminGuideArticle.vue"),
                        meta: {
                            title: 'User Guide - ' + companyName
                        }
                    }
                ]
            },

            {
                path: 'activities',
                name: 'activities',
                component: () => import("@/components/admin/activities/Activities.vue")  ,
                children: [


                ]
            },

            {
                path: 'payments',
                name: 'payments',
                component: () => import("@/components/admin/payments/Payments.vue"),
                children: [
                    {
                        path: '',
                        name: 'list-payments',
                        component: () => import("@/components/admin/payments/PaymentList.vue"),
                        meta: {
                            title: 'Payment - ' + companyName
                        }
                    },
                    {
                        path: 'add',
                        name: 'add-payment',
                        component: () => import("@/components/admin/payments/PaymentAdd.vue"),
                        meta: {
                            title: 'Payment - ' + companyName
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'edit-payment',
                        component: () => import("@/components/admin/payments/PaymentEdit.vue"),
                        meta: {
                            title: 'Payment - ' + companyName
                        }
                    }

                ]

            },

            {
                path: 'aotearoa-departures',
                name: 'aotearoa-departures',
                component: () => import("@/components/admin/regular-tours/aotearoa/Departures.vue"),
                children: [
                    {
                        path: '',
                        name: 'list-departures',
                        component: () => import("@/components/admin/regular-tours/aotearoa/DepartureList.vue"),
                        meta: {
                            title: 'Regular Tours - ' + companyName
                        }
                    },
                    {
                        path: 'add',
                        name: 'add-departure',
                        component: () => import("@/components/admin/regular-tours/aotearoa/DepartureAdd.vue"),
                        meta: {
                            title: 'Regular Tours - ' + companyName
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'edit-departure',
                        component: () => import("@/components/admin/regular-tours/aotearoa/DepartureEdit.vue"),
                        meta: {
                            title: 'Regular Tours - ' + companyName
                        }
                    }

                ]

            },

            {
                path: 'across-departures',
                name: 'across-departures',
                component: () => import("@/components/admin/regular-tours/across/Departures.vue"),
                children: [
                    {
                        path: '',
                        name: 'list-across-departures',
                        component: () => import("@/components/admin/regular-tours/across/DepartureList.vue"),
                        meta: {
                            title: 'Regular Tours - ' + companyName
                        }
                    },
                    {
                        path: 'add',
                        name: 'add-across-departure',
                        component: () => import("@/components/admin/regular-tours/across/DepartureAdd.vue"),
                        meta: {
                            title: 'Regular Tours - ' + companyName
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'edit-across-departure',
                        component: () => import("@/components/admin/regular-tours/across/DepartureEdit.vue"),
                        meta: {
                            title: 'Regular Tours - ' + companyName
                        }
                    }

                ]

            },

            {
                path: 'india-departures',
                name: 'india-departures',
                component: () => import("@/components/admin/regular-tours/india/Departures.vue"),
                children: [
                    {
                        path: '',
                        name: 'list-india-departures',
                        component: () => import("@/components/admin/regular-tours/india/DepartureList.vue"),
                        meta: {
                            title: 'Regular Tours - ' + companyName
                        }
                    },
                    {
                        path: 'add',
                        name: 'add-india-departure',
                        component: () => import("@/components/admin/regular-tours/india/DepartureAdd.vue"),
                        meta: {
                            title: 'Regular Tours - ' + companyName
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'edit-india-departure',
                        component: () => import("@/components/admin/regular-tours/india/DepartureEdit.vue"),
                        meta: {
                            title: 'Regular Tours - ' + companyName
                        }
                    }

                ]

            },

            {
                path: 'roles',
                name: 'roles',
                component: () => import("@/components/admin/permissions/Permissions.vue"),
                children: [
                    {
                        path: '',
                        name: 'list-roles',
                        component: () => import("@/components/admin/permissions/RolesList.vue"),
                        meta: {
                            title: 'Roles - ' + companyName
                        }
                    },

                    {
                        path: 'edit/:id',
                        name: 'edit-role',
                        component: () => import("@/components/admin/permissions/RoleEdit.vue"),
                        meta: {
                            title: 'Edit Role - ' + companyName
                        }
                    },

                    {
                        path: 'permissions',
                        name: 'list-permissions',
                        component: () => import("@/components/admin/permissions/PermissionsList.vue"),
                        meta: {
                            title: 'Permissions - ' + companyName
                        }
                    },

                ]
            },

            {
                path: 'insights',
                name: 'insights',
                component: () => import("@/components/admin/insights/Insights.vue")  ,
                children: [
                    {
                        path: '',
                        name: 'activities-overview',
                        component: () => import("@/components/admin/insights/ActivitiesOverview.vue"),
                        meta: {
                            title: 'Activities - ' + companyName
                        }
                    },

                    {
                        path: 'edited-services',
                        name: 'edited-services',
                        component: () => import("@/components/admin/insights/EditedServices.vue"),
                        meta: {
                            title: 'Edited Services - ' + companyName
                        }
                    }

                ]
            },

            {
                path: 'reviews',
                name: 'reviews',
                component: () => import("@/components/admin/service-reviews/ReviewList.vue"),
                meta: {
                    title: 'Reviews - ' + companyName
                }
            },


        ]
    },

    {
        path: '/app',
        component: () => import("@/components/user/UserDashboard.vue"),
        children: [

            {
                path: 'my-files/invoices/:ref',
                component: MyInvoices,
                name: 'my-invoices'
            },

            {
                path: 'list-bookings',
                name: 'list-bookings',
                component: () => import("@/components/user/bookings/ListBookings.vue"),
                meta: {
                    title: 'My Files - ' + companyName
                }
            },

            {
                path: 'view-booking/:ref',
                name: 'view-booking',
                component: () => import("@/components/user/bookings/ViewBooking.vue"),
                meta: {
                    title: 'File - ' + companyName
                }
            },

            {
                path: 'quick-quote',
                name: 'packages',
                //component: Packages,
                component: () => import("@/components/user/quick-quote/Packages.vue"),
                meta: {
                    title: 'Quick Quote - ' + companyName
                }
            },

            {
                path: 'quick-quote/:opt',
                name: 'package',
                //component: Package,
                component: () => import("@/components/user/quick-quote/Package.vue"),
                meta: {
                    title: 'Quick Quote - ' + companyName
                }
            },

            {
                path: 'quick-quote/customise/:ref',
                name: 'customise',
                component: () => import("@/components/user/quick-quote/Customise.vue"),
                meta: {
                    title: 'Quick Quote - ' + companyName
                }
            },

            {
                path: 'quick-quote/copy/:ref',
                name: 'copy',
                component: () => import("@/components/user/quick-quote/CopyQuote.vue"),
                meta: {
                    title: 'Quick Quote - ' + companyName
                }
            },

            {
                path: 'regular-tours-booking',
                name: 'regular-tours-booking',
                component: () => import("@/components/user/aotearoa/Booking.vue"),
                meta: {
                    title: 'Quick Quote - ' + companyName
                }
            },
        ]
    },

    {
        path: '/supplier-app',
        component: () => import("@/components/supplier/SupplierDashboard.vue"),
        children: [

            {
                path: 'requests',
                name: 'supplier-requests',
                component: () => import("@/components/supplier/requests/RequestsList.vue"),
                meta: {
                    title: 'Current Requests - ' + companyName
                }
            },

            {
                path: 'confirmed',
                name: 'supplier-confirmed',
                component: () => import("@/components/supplier/requests/ConfirmedList.vue"),
                meta: {
                    title: 'Confirmed Bookings - ' + companyName
                }
            },

            {
                path: 'cancelled',
                name: 'supplier-cancelled',
                component: () => import("@/components/supplier/requests/CancelledList.vue"),
                meta: {
                    title: 'Cancelled Bookings - ' + companyName
                }
            },

            {
                path: 'service/:id',
                name: 'supplier-service',
                component: () => import("@/components/supplier/SingleService.vue"),
                meta: {
                    title: 'Confirm Service - ' + companyName
                }
            },

        ]
    },

    {
        path: '/supplier/login',
        name: 'supplier-login',
        component: () => import("@/components/supplier/auth/Login.vue")
    },

    {
        path: '/supplier/service/:id',
        name: 'public-supplier-service',
        component: () => import("@/components/supplier/SingleServiceNoLogin.vue"),
        meta: {
            title: 'Confirm Service - ' + companyName
        }
    },

    {
        path: '/supplier/service-snapshot/:id',
        name: 'public-supplier-service-snapshot',
        component: () => import("@/components/supplier/SingleServiceSnapshot.vue"),
        meta: {
            title: 'Snapshot - ' + companyName
        }
    },

    {
        path: '/supplier/reconfirm/:id',
        name: 'public-supplier-reconfirm',
        component: () => import("@/components/supplier/SingleServiceReconfirmation.vue"),
        meta: {
            title: 'Reconfirm Service - ' + companyName
        }
    },

    {
        path: '/travel-app/:id?',
        component: () => import("@/components/client/TravelApp.vue"),
        name: 'travel-app'
    },

    {
        path: '/quote/:id?',
        component: () => import("@/components/client/Quote.vue"),
        name: 'client-quote'
    },

    {
        path: '/travel-app/',
        component: FindTravelApp,
        name: 'find-travel-app'
    },

    {
        path: '/payments/:id',
        name: 'pay',
        component: () => import("@/components/payments/PaymentSession.vue"),
        meta: {
            title: 'Payment - ' + companyName
        }
    },

    {
        path: '/payments/result',
        name: 'payment-result',
        component: () => import("@/components/payments/PaymentResult.vue"),
        meta: {
            title: 'Payment - ' + companyName
        }
    }
]
const router = createRouter({
    history: createWebHistory(),
    routes,
    // linkActiveClass: "dark:bg-slate-800 bg-gray-200",
    // linkExactActiveClass: "dark:bg-slate-800 bg-gray-200",
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
      },
})

router.beforeEach((toRoute, fromRoute, next) => {
    window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : companyName;

    next();
  })

//router.beforeEach(async (to) => {
//    let adminRoutesObject = [];
//    for (let i = 0; i < routes.length; i++) {
//        if (routes[i].path === '/admin') {
//            adminRoutesObject = routes[i]
//        }
//    }
//
//    let userRoutesObject = [];
//    for (let i = 0; i < routes.length; i++) {
//        if (routes[i].path === '/app') {
//            userRoutesObject = routes[i]
//        }
//    }
//
//    function getValue(o, findKey) {
//        const output = []
//
//        for (const k in o) {
//            if (k === findKey)
//                output.push(o[findKey])
//            else if (typeof o[k] === 'object')
//                output.push(...getValue(o[k], findKey))
//        }
//
//        return output;
//    }
//
//    const adminRoutes = getValue(adminRoutesObject, 'name');
//    const adminAuthRequired = adminRoutes.includes(to.name);
//    const admin = useAdminStore();
//
//    const userRoutes = getValue(userRoutesObject, 'name');
//    const userAuthRequired = userRoutes.includes(to.name);
//    const user = useUserStore();
//
//    //if (adminAuthRequired && (!admin.admin || !admin.admin.data || !admin.admin.data.name)){
//    //    admin.redirect = to.fullPath;
//    //    return '/admin/login';
//    //}
//
//    //if (userAuthRequired && (!user.user || !user.user.data || !user.user.data.agent_display_name)) {
//    //    user.redirect = to.fullPath;
//    //    return '/';
//    //}
//})


// After update is deployed this will refresh a browser if the route returns this error (Which it usually will).
router.onError((error, to) => {

    if (error.message.includes('Failed to fetch dynamically imported module')) {
        window.location = to.fullPath
    }

})


export default router
